<template>
    <section style="height: 100%;">
        <div class="flex align-center" style="padding: 15px 0;">
            <img :src="bs" alt=""/>
            <span style="color:#FFFFFF;font-size: 18px;font-weight: bold;margin-left: 10px">租房占比</span>
        </div>
        <div style="height: 75%;border: 1px #2BA3FF dashed;padding: 10px;">
            <div class="flex align-center" style="height: 13%;color:#FFFFFF;">
                <div class="flex justify-center align-center" @click="typeChange(0)"
                     :style="type===1?'background: #2E4CCF;box-shadow: inset 0 0 22px 1px #03024A;':'background: linear-gradient(90deg, #EF9A50 0%, #F31900 100%);'"
                     style="height: 100%;width: 90px;">
                    户型占比
                </div>
                <div style="width: 30px;"></div>
                <div class="flex justify-center align-center" @click="typeChange(1)"
                     :style="type===0?'background: #2E4CCF;box-shadow: inset 0 0 22px 1px #03024A;':'background: linear-gradient(90deg, #EF9A50 0%, #F31900 100%);'"
                     style="height: 100%;width: 90px;">
                    年龄占比
                </div>
            </div>
            <div class="flex justify-between" style="height: 87%;">
                <div id="tenancyECharts" style="height: 100%;width: 59%;"></div>
                <div style="height: 100%;width: 39%;">
                    <div style="font-size: 14px;font-weight: normal;color: #FFFFFF;">
                        <div v-for="(item,index) in list" :key="index" class="flex justify-between align-center"
                             style="margin-bottom: 5px;">
                            <div
                                :style="'background: linear-gradient(180deg, ' + item.beginBackground + ' 0%, ' + item.finishBackground + ' 100%)'"
                                style="width: 10px;height: 10px;border-radius: 5px;"></div>
                            <div class="flex" style="width: 90%;font-size: 12px">
                                <div style="width: 85px;text-align-last: justify;">{{ item.name }}</div>
                                <div style="padding-left: 10px;">{{ item.rate }}%</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import * as echarts from "echarts";

const bs = require("@images/icon/bs.png");

import {getRentProportion} from "@/api/data-screen";

export default {
    name: "tenancy-proportion",
    data() {
        return {
            bs,
            ECharts: null,
            soilSettles: [
                {
                    beginBackground: '#026DB2',
                    finishBackground: '#12FEE0',
                },
                {
                    beginBackground: '#6DD400',
                    finishBackground: '#44D7B6',
                },
                {
                    beginBackground: '#5C64FF',
                    finishBackground: '#6988F8',
                },
                {
                    beginBackground: '#FFA600',
                    finishBackground: '#FEDB65',
                },
                {
                    beginBackground: '#EF9A50',
                    finishBackground: '#F31900',
                },
                {
                    beginBackground: '#026DB2',
                    finishBackground: '#12FEE0',
                },
                {
                    beginBackground: '#6DD400',
                    finishBackground: '#44D7B6',
                },
                {
                    beginBackground: '#5C64FF',
                    finishBackground: '#6988F8',
                },
                {
                    beginBackground: '#FFA600',
                    finishBackground: '#FEDB65',
                },
                {
                    beginBackground: '#EF9A50',
                    finishBackground: '#F31900',
                }
            ],
            options: {
                tooltip: {
                    trigger: "item",
                },
                series: [
                    {
                        type: 'pie',
                        radius: ['30%', '90%'],
                        label: {
                            normal: {
                                show: false,
                            }
                        },
                        tooltip: {
                            valueFormatter(value) {
                                return value + ' %';
                            }
                        },
                    }
                ]
            },
            list: [],
            type: 0,
        }
    },
    components: {},
    methods: {
        eChartsInit(eChartsName, eChartsOptions) {
            let {...options} = eChartsOptions;
            this[eChartsName] = echarts.init(document.getElementById(eChartsName));
            this[eChartsName].setOption(options, true);
            window.addEventListener("resize", () => {
                this[eChartsName].resize();
            });
        },
        formatOption(eChartsOptions, data) {
            const {soilSettles} = this;
            const {series} = eChartsOptions;

            data = data.map((item, i) => {
                const {rate: value} = item;
                soilSettles.map((v, k) => {
                    if (i === k) {
                        item.itemStyle = {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                {offset: 0, color: v.beginBackground},
                                {offset: 1, color: v.finishBackground}
                            ])
                        }
                    }
                });
                return {...item, value};
            });

            series[0].data = data;
            eChartsOptions.series = series;
            return eChartsOptions;
        },

        getRentProportion() {
            const that = this;
            const {options, soilSettles} = that;
            getRentProportion({type: 0}).then(res => {
                const {list} = res;
                const eChartsOptions = that.formatOption(options, list);
                that.eChartsInit("tenancyECharts", eChartsOptions);
                that.list = list.map((v, i) => {
                    return {...soilSettles[i], ...v}
                });
            });
        },
        typeChange(type) {
            const that = this;
            that.type = type;
            const {options, soilSettles} = that;
            getRentProportion({type}).then(res => {
                const {list} = res;
                const eChartsOptions = that.formatOption(options, list);
                this['tenancyECharts'].setOption(eChartsOptions, true);
                that.list = list.map((v, i) => {
                    return {...soilSettles[i], ...v}
                });
            });
        }
    },
    mounted() {
        this.getRentProportion();
        /*const {options} = this;
        const data = [
            {
                value: 200,
                name: "富航公寓",
            },
            {
                value: 200,
                name: "回购经适房",
            },
            {
                value: 200,
                name: "拆迁安置房",
            },
            {
                value: 200,
                name: "竞配产权房",
            },
            {
                value: 200,
                name: "高级人才房",
            }
        ]
        const eChartsOptions = this.formatOption(options, data);
        this.eChartsInit("tenancyECharts", eChartsOptions);*/
    }
}
</script>

<style scoped>

</style>